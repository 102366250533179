
import { defineComponent } from 'vue';
import GameCoaches from '@/components/games/GameCoaches.vue';
import GameInfo from '@/components/games/GameInfo.vue';
import GamePlayers from '@/components/games/GamePlayers.vue';
import GameTeams from '@/components/games/GameTeams.vue';
import { GameInterface } from '@/interfaces/games';

export default defineComponent({
  components: {
    GameCoaches,
    GameInfo,
    GamePlayers,
    GameTeams,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      (vm as any).loadGame();
    });
  },
  beforeRouteUpdate(to, from, next) {
    next();

    setTimeout(() => {
      this.loadGame();
    }, 0);
  },
  props: {
    gameSlug: {
      type: String,
      required: true,
    },
    activeTab: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    gameStatus: 'loading' as 'loading' | 'loaded' | 'not_found' | 'error',
    game: {} as GameInterface,
  }),
  methods: {
    async loadGame() {
      if (this.gameStatus === 'loaded' && this.game.slug === this.gameSlug) {
        return;
      }

      this.gameStatus = 'loading';

      const responseData = await this.api({
        url: `games/${this.gameSlug}/`,
      });

      if (responseData.status === 200) {
        this.game = responseData.body;
        this.gameStatus = 'loaded';
      } else if (responseData.status === 404) {
        this.gameStatus = 'not_found';
      } else {
        this.gameStatus = 'error';
      }
    },
  },
});

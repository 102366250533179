<template>
  <spinner
    v-if="status === 'loading'"
    preset="large"
  />

  <template v-else-if="status === 'error'">
    <alert variant="danger">
      An error occurred loading the coaches. Please check your connection and
      try again.
    </alert>

    <button
      type="button"
      class="btn btn-outline-primary"
      @click="loadCoaches"
    >
      Try Again
    </button>
  </template>

  <template v-else-if="status === 'loaded'">
    <button
      type="button"
      class="btn btn-outline-primary coach-toggle-button"
      :class="{
        'btn-danger': userIsACoach,
        'btn-outline-primary': !userIsACoach,
      }"
      @click="toggleButtonClicked"
    >
      <template v-if="togglingCoachStatus">
        Processing
        <spinner />
      </template>

      <template v-else-if="userIsACoach">
        I'm No Longer a Coach for This Game
      </template>

      <template v-else>
        I'm a Coach for This Game
      </template>
    </button>

    <modal
      v-if="gamePlatforms.length > 1"
      ref="platformsModal"
      :show="platformsModalShowing"
      class="platforms-modal"
      @modalClosed="platformsModalShowing = false"
    >
      <template #header>
        <h2>Choose Platforms</h2>
      </template>

      <div class="horizontal-padding">
        <p class="text-center">
          Which platforms do you coach {{ game.name }} on?
        </p>

        <div
          v-if="platformsErrorMessageShowing"
          class="invalid-feedback js-form-error"
        >
          Please choose at least one platform.
        </div>

        <div class="platform-checkboxes">
          <div
            v-for="(platform, index) of gamePlatforms"
            :key="`platform${index}`"
            class="form-check"
          >
            <input
              :id="`platform${index}`"
              v-model="selectedPlatforms"
              type="checkbox"
              :value="platform"
              class="form-check-input"
            >
            <label
              :for="`platform${index}`"
              class="form-check-label"
            >
              {{ platform }}
            </label>
          </div>
        </div>

        <button
          type="button"
          class="btn btn-primary platforms-submit-button"
          @click="platformsSubmitButtonClicked"
        >
          Submit
        </button>
      </div>
    </modal>

    <template v-if="coaches.results.length">
      <div class="coaches">
        <router-link
          v-for="coach of coaches.results"
          :key="`coach${coach.user.username}`"
          :to="{ name: 'user_profile', params: { username: coach.user.username } }"
          class="coach"
        >
          <profile-image :user="coach.user" />
          <div>
            <div>{{ coach.user.first_name }} {{ coach.user.last_name }}</div>

            <template v-if="gamePlatforms.length > 1">
              Coaches on {{ toListSentence(coach.platforms) }}
            </template>
          </div>
        </router-link>
      </div>

      <div class="load-more-content">
        <button
          v-show="moreCoachesStatus === 'idle' && coaches.next"
          ref="loadMoreCoachesButton"
          type="button"
          class="btn btn-outline-primary load-more-coaches-button"
          @click="loadMoreCoaches"
        >
          Load More Coaches
        </button>

        <spinner
          v-if="moreCoachesStatus === 'loading'"
          preset="large"
        />

        <template v-else-if="moreCoachesStatus === 'error'">
          <alert variant="danger">
            An error occurred while trying to load more coaches. Please check
            your connection and try again.
          </alert>

          <button
            type="button"
            class="btn btn-outline-primary"
            @click="loadMoreCoaches"
          >
            Try Again
          </button>
        </template>
      </div>
    </template>

    <p
      v-else
      class="text-center"
    >
      Nobody on The Players Arena coaches for this game yet.
    </p>
  </template>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Modal from '@/components/Modal.vue';
import ProfileImage from '@/components/users/ProfileImage.vue';
import { CoachUserInterface, GameInterface } from '@/interfaces/games';

export default defineComponent({
  components: {
    Modal,
    ProfileImage,
  },
  props: {
    game: {
      type: Object as PropType<GameInterface>,
      required: true,
    },
  },
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'error',
    coaches: {} as {
      next: string | null,
      previous: string | null,
      results: Array<CoachUserInterface>,
    },
    moreCoachesStatus: 'idle' as 'idle' | 'loading' | 'error',
    observer: null as null | IntersectionObserver,
    gamePlatforms: [] as Array<string>,
    userIsACoach: false,
    togglingCoachStatus: false,
    platformsModalShowing: false,
    platformsErrorMessageShowing: false,
    selectedPlatforms: [] as Array<string>,
  }),
  watch: {
    platformsErrorMessageShowing() {
      if (this.platformsErrorMessageShowing) {
        this.$nextTick(() => {
          this.scrollToFirstError((this.$refs.platformsModal as InstanceType<typeof Modal>).$el);
        });
      }
    },
  },
  created() {
    this.loadCoaches();
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    async loadCoaches() {
      this.status = 'loading';

      const responseData = await this.api({
        url: `games/${this.game.slug}/coaches/`,
      });

      if (responseData.status === 200) {
        this.coaches = responseData.body.coaches;
        this.gamePlatforms = responseData.body.game_platforms;
        this.userIsACoach = responseData.body.user_is_a_coach;
        this.status = 'loaded';

        if (this.coaches.next) {
          this.$nextTick(() => {
            this.observer = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.intersectionRatio) {
                  this.loadMoreCoaches();
                }
              });
            });

            this.observer.observe(this.$refs.loadMoreCoachesButton as HTMLButtonElement);
          });
        }
      } else {
        this.status = 'error';
      }
    },
    async loadMoreCoaches() {
      this.moreCoachesStatus = 'loading';

      const responseData = await this.api({
        url: this.coaches.next as string,
      });

      if (responseData.status === 200) {
        this.coaches.next = responseData.body.next;
        this.coaches.previous = responseData.body.previous;
        this.coaches.results = this.coaches.results.concat(responseData.body.results);
        this.moreCoachesStatus = 'idle';
      } else {
        this.moreCoachesStatus = 'error';
      }
    },
    platformsSubmitButtonClicked() {
      this.platformsErrorMessageShowing = false;

      this.$nextTick(() => {
        if (this.selectedPlatforms.length) {
          (this.$refs.platformsModal as InstanceType<typeof Modal>).closeModal();
          this.toggleCoachStatus();
        } else {
          this.platformsErrorMessageShowing = true;
        }
      });
    },
    toggleButtonClicked() {
      if (
        !this.userIsACoach
        && this.gamePlatforms.length > 1
        && this.selectedPlatforms.length === 0
      ) {
        this.platformsModalShowing = true;
      } else {
        this.toggleCoachStatus();
      }
    },
    async toggleCoachStatus() {
      if (!this.togglingCoachStatus) {
        this.togglingCoachStatus = true;

        const json = {
          action: this.userIsACoach ? 'remove' : 'add',
        } as Record<string, string | Array<string>>;

        if (!this.userIsACoach && this.selectedPlatforms.length) {
          json.platforms = JSON.parse(JSON.stringify(this.selectedPlatforms));
        }

        const responseData = await this.api({
          url: `games/${this.game.slug}/coaches/`,
          method: 'POST',
          json,
        });

        this.togglingCoachStatus = false;

        if (responseData.ok) {
          if (!this.userIsACoach && this.selectedPlatforms.length) {
            this.selectedPlatforms = [];
          }

          this.userIsACoach = !this.userIsACoach;

          if (this.userIsACoach) {
            this.coaches.results.unshift(responseData.body);
          } else {
            this.coaches.results = this.coaches.results.filter(
              (p) => p.user.username !== this.userData.username,
            );
          }
        } else {
          const title = 'Failed to Change Your Coach Status';
          let text;

          if (responseData.status === 404) {
            text = 'This game no longer exists.';
          } else {
            text = 'Please check your connection and try again.';
          }

          this.$swal(title, text);
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
  .coach-toggle-button,
  .platforms-submit-button,
  .load-more-coaches-button {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .coach-toggle-button {
    margin-bottom: 1rem;
  }

  .platform-checkboxes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: end;
    margin-bottom: 1rem;
  }

  .coaches {
    border-radius: 6px;
    overflow: hidden;
  }

  .coach {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: auto minmax(0, 1fr);
    align-items: center;
    padding: 10px 16px;
    color: #fff;
    background-color: var(--gray-darker);
    transition: background-color 0.15s ease-in-out;
    word-wrap: break-word;

    @supports #{'\selector(*:focus-visible)'}  {
      &:focus {
        outline: 0;
      }

      &:focus-visible {
        background-color: var(--gray-dark);
      }
    }

    &:hover {
      background-color: var(--gray-dark);
      text-decoration: none;
    }

    + .coach {
      border-top: 1px solid var(--gray-darkest);
    }
  }

  .load-more-content {
    margin-top: 1rem;
  }
</style>

<template>
  <spinner
    v-if="status === 'loading'"
    preset="large"
  />

  <template v-else-if="status === 'error'">
    <alert variant="danger">
      An error occurred loading the game info. Please check your connection and
      try again.
    </alert>

    <button
      type="button"
      class="btn btn-outline-primary"
      @click="loadGameInfo"
    >
      Try Again
    </button>
  </template>

  <div
    v-else-if="status === 'loaded'"
    class="card"
  >
    <p>Game type: {{ gameInfo.category }}</p>

    <p>Platforms: {{ gameInfo.platforms.join(', ') }}</p>

    <p>Release date: {{ dateDisplay(game.first_release_date) }}</p>

    <div
      v-if="gameInfo.summary !== ''"
      v-html="`Summary: ${summaryHtml()}`"
    />
  </div>
</template>

<script lang="ts">
import linkify from 'linkifyjs/html';
import { defineComponent, PropType } from 'vue';
import { GameInterface } from '@/interfaces/games';

export default defineComponent({
  props: {
    game: {
      type: Object as PropType<GameInterface>,
      required: true,
    },
  },
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'error',
    gameInfo: {} as {
      category: string;
      platforms: string[];
      summary: string;
    },
  }),
  created() {
    this.loadGameInfo();
  },
  methods: {
    summaryHtml() {
      return linkify(
        this.linebreaksbr(this.gameInfo.summary),
        {
          defaultProtocol: 'https',
        },
      );
    },
    async loadGameInfo() {
      this.status = 'loading';

      const responseData = await this.api({
        url: `games/${this.game.slug}/info/`,
      });

      if (responseData.status === 200) {
        this.gameInfo = responseData.body;
        this.status = 'loaded';
      } else {
        this.status = 'error';
      }
    },
  },
});
</script>

<style lang="scss" scoped>
</style>

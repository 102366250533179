<template>
  <div class="horizontal-padding">
    <template v-if="gameStatus === 'loaded'">
      <h1>{{ game.name }}</h1>

      <img
        v-if="game.cover_image_id"
        :src="`https://images.igdb.com/igdb/image/upload/t_cover_big/${game.cover_image_id}.jpg`"
        alt
        class="img-fluid cover-image"
      >

      <nav
        class="navbar"
        aria-label="Game"
      >
        <ul>
          <li>
            <router-link :to="{ name: 'game', params: { gameSlug } }">
              Info
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'game_players_casual', params: { gameSlug } }">
              Casual Players
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'game_players_competitive', params: { gameSlug } }">
              Competitive Players
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'game_coaches', params: { gameSlug } }">
              Coaches
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'game_teams', params: { gameSlug } }">
              Teams
            </router-link>
          </li>
        </ul>
      </nav>

      <div class="content">
        <game-info
          v-if="activeTab === 'info'"
          :game="game"
        />

        <game-players
          v-else-if="activeTab === 'players_casual'"
          :game="game"
          category="casual"
        />

        <game-players
          v-else-if="activeTab === 'players_competitive'"
          :game="game"
          category="competitive"
        />

        <game-coaches
          v-else-if="activeTab === 'coaches'"
          :game="game"
        />

        <game-teams
          v-else-if="activeTab === 'teams'"
          :game="game"
        />
      </div>
    </template>

    <spinner
      v-else-if="gameStatus === 'loading'"
      preset="large"
    />

    <template v-else-if="gameStatus === 'error'">
      <alert variant="danger">
        An error occurred loading the game. Please check your connection and
        try again.
      </alert>

      <button
        type="button"
        class="btn btn-outline-primary"
        @click="loadGame"
      >
        Try Again
      </button>
    </template>

    <alert
      v-else-if="gameStatus === 'not_found'"
      variant="danger"
    >
      That game does not exist.
    </alert>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import GameCoaches from '@/components/games/GameCoaches.vue';
import GameInfo from '@/components/games/GameInfo.vue';
import GamePlayers from '@/components/games/GamePlayers.vue';
import GameTeams from '@/components/games/GameTeams.vue';
import { GameInterface } from '@/interfaces/games';

export default defineComponent({
  components: {
    GameCoaches,
    GameInfo,
    GamePlayers,
    GameTeams,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      (vm as any).loadGame();
    });
  },
  beforeRouteUpdate(to, from, next) {
    next();

    setTimeout(() => {
      this.loadGame();
    }, 0);
  },
  props: {
    gameSlug: {
      type: String,
      required: true,
    },
    activeTab: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    gameStatus: 'loading' as 'loading' | 'loaded' | 'not_found' | 'error',
    game: {} as GameInterface,
  }),
  methods: {
    async loadGame() {
      if (this.gameStatus === 'loaded' && this.game.slug === this.gameSlug) {
        return;
      }

      this.gameStatus = 'loading';

      const responseData = await this.api({
        url: `games/${this.gameSlug}/`,
      });

      if (responseData.status === 200) {
        this.game = responseData.body;
        this.gameStatus = 'loaded';
      } else if (responseData.status === 404) {
        this.gameStatus = 'not_found';
      } else {
        this.gameStatus = 'error';
      }
    },
  },
});
</script>

<style lang="scss" scoped>
  .cover-image {
    display: block;
    margin: 0 auto;
  }

  .content {
    margin: 0 auto;
    max-width: 500px;
  }
</style>

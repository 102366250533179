
import { defineComponent, PropType } from 'vue';
import Modal from '@/components/Modal.vue';
import ProfileImage from '@/components/users/ProfileImage.vue';
import { GameInterface, PlayerUserInterface } from '@/interfaces/games';

export default defineComponent({
  components: {
    Modal,
    ProfileImage,
  },
  props: {
    game: {
      type: Object as PropType<GameInterface>,
      required: true,
    },
    category: {
      type: String,
      required: true,
      validator: (value: string) => [
        'casual',
        'competitive',
      ].includes(value),
    },
  },
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'error',
    players: {} as {
      next: string | null,
      previous: string | null,
      results: Array<PlayerUserInterface>,
    },
    morePlayersStatus: 'idle' as 'idle' | 'loading' | 'error',
    observer: null as null | IntersectionObserver,
    gamePlatforms: [] as Array<string>,
    userIsAPlayer: false,
    togglingPlayerStatus: false,
    platformsModalShowing: false,
    platformsErrorMessageShowing: false,
    selectedPlatforms: [] as Array<string>,
  }),
  watch: {
    platformsErrorMessageShowing() {
      if (this.platformsErrorMessageShowing) {
        this.$nextTick(() => {
          this.scrollToFirstError((this.$refs.platformsModal as InstanceType<typeof Modal>).$el);
        });
      }
    },
  },
  created() {
    this.loadPlayers();
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    async loadPlayers() {
      this.status = 'loading';

      const responseData = await this.api({
        url: `games/${this.game.slug}/players/?category=${this.category}`,
      });

      if (responseData.status === 200) {
        this.players = responseData.body.players;
        this.gamePlatforms = responseData.body.game_platforms;
        this.userIsAPlayer = responseData.body.user_is_a_player;
        this.status = 'loaded';

        if (this.players.next) {
          this.$nextTick(() => {
            this.observer = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.intersectionRatio) {
                  this.loadMorePlayers();
                }
              });
            });

            this.observer.observe(this.$refs.loadMorePlayersButton as HTMLButtonElement);
          });
        }
      } else {
        this.status = 'error';
      }
    },
    async loadMorePlayers() {
      this.morePlayersStatus = 'loading';

      const responseData = await this.api({
        url: this.players.next as string,
      });

      if (responseData.status === 200) {
        this.players.next = responseData.body.next;
        this.players.previous = responseData.body.previous;
        this.players.results = this.players.results.concat(responseData.body.results);
        this.morePlayersStatus = 'idle';
      } else {
        this.morePlayersStatus = 'error';
      }
    },
    platformsSubmitButtonClicked() {
      this.platformsErrorMessageShowing = false;

      this.$nextTick(() => {
        if (this.selectedPlatforms.length) {
          (this.$refs.platformsModal as InstanceType<typeof Modal>).closeModal();
          this.togglePlayerStatus();
        } else {
          this.platformsErrorMessageShowing = true;
        }
      });
    },
    toggleButtonClicked() {
      if (
        !this.userIsAPlayer
        && this.gamePlatforms.length > 1
        && this.selectedPlatforms.length === 0
      ) {
        this.platformsModalShowing = true;
      } else {
        this.togglePlayerStatus();
      }
    },
    async togglePlayerStatus() {
      if (!this.togglingPlayerStatus) {
        this.togglingPlayerStatus = true;

        const json = {
          action: this.userIsAPlayer ? 'remove' : 'add',
        } as Record<string, string | Array<string>>;

        if (!this.userIsAPlayer && this.selectedPlatforms.length) {
          json.platforms = JSON.parse(JSON.stringify(this.selectedPlatforms));
        }

        const responseData = await this.api({
          url: `games/${this.game.slug}/players/?category=${this.category}`,
          method: 'POST',
          json,
        });

        this.togglingPlayerStatus = false;

        if (responseData.ok) {
          if (!this.userIsAPlayer && this.selectedPlatforms.length) {
            this.selectedPlatforms = [];
          }

          this.userIsAPlayer = !this.userIsAPlayer;

          if (this.userIsAPlayer) {
            this.players.results.unshift(responseData.body);
          } else {
            this.players.results = this.players.results.filter(
              (p) => p.user.username !== this.userData.username,
            );
          }
        } else {
          const title = 'Failed to Change Your Player Status';
          let text;

          if (responseData.status === 404) {
            text = 'This game no longer exists.';
          } else {
            text = 'Please check your connection and try again.';
          }

          this.$swal(title, text);
        }
      }
    },
  },
});

<template>
  <spinner
    v-if="status === 'loading'"
    preset="large"
  />

  <template v-else-if="status === 'error'">
    <alert variant="danger">
      An error occurred loading the players. Please check your connection and
      try again.
    </alert>

    <button
      type="button"
      class="btn btn-outline-primary"
      @click="loadPlayers"
    >
      Try Again
    </button>
  </template>

  <template v-else-if="status === 'loaded'">
    <button
      type="button"
      class="btn btn-outline-primary player-toggle-button"
      :class="{
        'btn-danger': userIsAPlayer,
        'btn-outline-primary': !userIsAPlayer,
      }"
      @click="toggleButtonClicked"
    >
      <template v-if="togglingPlayerStatus">
        Processing
        <spinner />
      </template>

      <template v-else-if="userIsAPlayer">
        I No Longer Play This Game

        <template v-if="category === 'casual'">
          Casually
        </template>

        <template v-else-if="category === 'competitive'">
          Competitively
        </template>
      </template>

      <template v-else>
        I Play This Game

        <template v-if="category === 'casual'">
          Casually
        </template>

        <template v-else-if="category === 'competitive'">
          Competitively
        </template>
      </template>
    </button>

    <modal
      v-if="gamePlatforms.length > 1"
      ref="platformsModal"
      :show="platformsModalShowing"
      class="platforms-modal"
      @modalClosed="platformsModalShowing = false"
    >
      <template #header>
        <h2>Choose Platforms</h2>
      </template>

      <div class="horizontal-padding">
        <p class="text-center">
          Which platforms do you play {{ game.name }} on?
        </p>

        <div
          v-if="platformsErrorMessageShowing"
          class="invalid-feedback js-form-error"
        >
          Please choose at least one platform.
        </div>

        <div class="platform-checkboxes">
          <div
            v-for="(platform, index) of gamePlatforms"
            :key="`platform${index}`"
            class="form-check"
          >
            <input
              :id="`platform${index}`"
              v-model="selectedPlatforms"
              type="checkbox"
              :value="platform"
              class="form-check-input"
            >
            <label
              :for="`platform${index}`"
              class="form-check-label"
            >
              {{ platform }}
            </label>
          </div>
        </div>

        <button
          type="button"
          class="btn btn-primary platforms-submit-button"
          @click="platformsSubmitButtonClicked"
        >
          Submit
        </button>
      </div>
    </modal>

    <template v-if="players.results.length">
      <div class="players">
        <router-link
          v-for="player of players.results"
          :key="`player${player.user.username}`"
          :to="{ name: 'user_profile', params: { username: player.user.username } }"
          class="player"
        >
          <profile-image :user="player.user" />
          <div>
            <div>{{ player.user.first_name }} {{ player.user.last_name }}</div>

            <template v-if="gamePlatforms.length > 1">
              Plays on {{ toListSentence(player.platforms) }}
            </template>
          </div>
        </router-link>
      </div>

      <div class="load-more-content">
        <button
          v-show="morePlayersStatus === 'idle' && players.next"
          ref="loadMorePlayersButton"
          type="button"
          class="btn btn-outline-primary load-more-players-button"
          @click="loadMorePlayers"
        >
          Load More Players
        </button>

        <spinner
          v-if="morePlayersStatus === 'loading'"
          preset="large"
        />

        <template v-else-if="morePlayersStatus === 'error'">
          <alert variant="danger">
            An error occurred while trying to load more players. Please check
            your connection and try again.
          </alert>

          <button
            type="button"
            class="btn btn-outline-primary"
            @click="loadMorePlayers"
          >
            Try Again
          </button>
        </template>
      </div>
    </template>

    <p
      v-else
      class="text-center"
    >
      Nobody on The Players Arena plays this game

      <template v-if="category === 'casual'">
        casually
      </template>

      <template v-else-if="category === 'competitive'">
        competitively
      </template>

      yet.
    </p>
  </template>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import Modal from '@/components/Modal.vue';
import ProfileImage from '@/components/users/ProfileImage.vue';
import { GameInterface, PlayerUserInterface } from '@/interfaces/games';

export default defineComponent({
  components: {
    Modal,
    ProfileImage,
  },
  props: {
    game: {
      type: Object as PropType<GameInterface>,
      required: true,
    },
    category: {
      type: String,
      required: true,
      validator: (value: string) => [
        'casual',
        'competitive',
      ].includes(value),
    },
  },
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'error',
    players: {} as {
      next: string | null,
      previous: string | null,
      results: Array<PlayerUserInterface>,
    },
    morePlayersStatus: 'idle' as 'idle' | 'loading' | 'error',
    observer: null as null | IntersectionObserver,
    gamePlatforms: [] as Array<string>,
    userIsAPlayer: false,
    togglingPlayerStatus: false,
    platformsModalShowing: false,
    platformsErrorMessageShowing: false,
    selectedPlatforms: [] as Array<string>,
  }),
  watch: {
    platformsErrorMessageShowing() {
      if (this.platformsErrorMessageShowing) {
        this.$nextTick(() => {
          this.scrollToFirstError((this.$refs.platformsModal as InstanceType<typeof Modal>).$el);
        });
      }
    },
  },
  created() {
    this.loadPlayers();
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    async loadPlayers() {
      this.status = 'loading';

      const responseData = await this.api({
        url: `games/${this.game.slug}/players/?category=${this.category}`,
      });

      if (responseData.status === 200) {
        this.players = responseData.body.players;
        this.gamePlatforms = responseData.body.game_platforms;
        this.userIsAPlayer = responseData.body.user_is_a_player;
        this.status = 'loaded';

        if (this.players.next) {
          this.$nextTick(() => {
            this.observer = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.intersectionRatio) {
                  this.loadMorePlayers();
                }
              });
            });

            this.observer.observe(this.$refs.loadMorePlayersButton as HTMLButtonElement);
          });
        }
      } else {
        this.status = 'error';
      }
    },
    async loadMorePlayers() {
      this.morePlayersStatus = 'loading';

      const responseData = await this.api({
        url: this.players.next as string,
      });

      if (responseData.status === 200) {
        this.players.next = responseData.body.next;
        this.players.previous = responseData.body.previous;
        this.players.results = this.players.results.concat(responseData.body.results);
        this.morePlayersStatus = 'idle';
      } else {
        this.morePlayersStatus = 'error';
      }
    },
    platformsSubmitButtonClicked() {
      this.platformsErrorMessageShowing = false;

      this.$nextTick(() => {
        if (this.selectedPlatforms.length) {
          (this.$refs.platformsModal as InstanceType<typeof Modal>).closeModal();
          this.togglePlayerStatus();
        } else {
          this.platformsErrorMessageShowing = true;
        }
      });
    },
    toggleButtonClicked() {
      if (
        !this.userIsAPlayer
        && this.gamePlatforms.length > 1
        && this.selectedPlatforms.length === 0
      ) {
        this.platformsModalShowing = true;
      } else {
        this.togglePlayerStatus();
      }
    },
    async togglePlayerStatus() {
      if (!this.togglingPlayerStatus) {
        this.togglingPlayerStatus = true;

        const json = {
          action: this.userIsAPlayer ? 'remove' : 'add',
        } as Record<string, string | Array<string>>;

        if (!this.userIsAPlayer && this.selectedPlatforms.length) {
          json.platforms = JSON.parse(JSON.stringify(this.selectedPlatforms));
        }

        const responseData = await this.api({
          url: `games/${this.game.slug}/players/?category=${this.category}`,
          method: 'POST',
          json,
        });

        this.togglingPlayerStatus = false;

        if (responseData.ok) {
          if (!this.userIsAPlayer && this.selectedPlatforms.length) {
            this.selectedPlatforms = [];
          }

          this.userIsAPlayer = !this.userIsAPlayer;

          if (this.userIsAPlayer) {
            this.players.results.unshift(responseData.body);
          } else {
            this.players.results = this.players.results.filter(
              (p) => p.user.username !== this.userData.username,
            );
          }
        } else {
          const title = 'Failed to Change Your Player Status';
          let text;

          if (responseData.status === 404) {
            text = 'This game no longer exists.';
          } else {
            text = 'Please check your connection and try again.';
          }

          this.$swal(title, text);
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
  .player-toggle-button,
  .platforms-submit-button,
  .load-more-players-button {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .player-toggle-button {
    margin-bottom: 1rem;
  }

  .platform-checkboxes {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: end;
    margin-bottom: 1rem;
  }

  .players {
    border-radius: 6px;
    overflow: hidden;
  }

  .player {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: auto minmax(0, 1fr);
    align-items: center;
    padding: 10px 16px;
    color: #fff;
    background-color: var(--gray-darker);
    transition: background-color 0.15s ease-in-out;
    word-wrap: break-word;

    @supports #{'\selector(*:focus-visible)'}  {
      &:focus {
        outline: 0;
      }

      &:focus-visible {
        background-color: var(--gray-dark);
      }
    }

    &:hover {
      background-color: var(--gray-dark);
      text-decoration: none;
    }

    + .player {
      border-top: 1px solid var(--gray-darkest);
    }
  }

  .load-more-content {
    margin-top: 1rem;
  }
</style>


import { defineComponent } from 'vue';
import { XIcon } from '@zhuowenli/vue-feather-icons';

export default defineComponent({
  components: {
    XIcon,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
    closeLabel: {
      type: String,
      required: false,
      default: 'Close',
    },
  },
  emits: [
    'modalClosing',
    'modalClosed',
  ],
  data: () => ({
    modalShowing: false,
    polyfilled: false,
  }),
  watch: {
    show() {
      this.showModalIfNecessary();
    },
  },
  mounted() {
    this.showModalIfNecessary();
  },
  methods: {
    backdropClicked(e: MouseEvent) {
      const modal = this.$refs.modal as HTMLDialogElement;

      if (e.target === modal) {
        this.closeModal();
      }
    },
    closeModal() {
      const modal = this.$refs.modal as HTMLDialogElement;
      modal.addEventListener('transitionend', this.closeModal2);
      this.modalShowing = false;
      this.$emit('modalClosing');
    },
    closeModal2(e: TransitionEvent) {
      const modal = this.$refs.modal as HTMLDialogElement;

      if (e.target === modal) {
        modal.removeEventListener('transitionend', this.closeModal2);
        // @ts-expect-error Property 'close' does not exist on type 'HTMLDialogElement'.
        modal.close();
        this.$emit('modalClosed');
      }
    },
    showModalIfNecessary() {
      if (this.show) {
        if (!this.polyfilled) {
          this.polyfilled = true;
          window.dialogPolyfill.registerDialog(this.$refs.modal);
        }

        const modal = this.$refs.modal as HTMLDialogElement;
        // @ts-expect-error Property 'showModal' does not exist on type 'HTMLDialogElement'.
        modal.showModal();
        window.app.activeModals.push(modal);
        this.modalShowing = true;
      }
    },
  },
});

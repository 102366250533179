
import { defineComponent, PropType } from 'vue';
import { GameInterface } from '@/interfaces/games';
import { TeamInterface } from '@/interfaces/teams';

export default defineComponent({
  props: {
    game: {
      type: Object as PropType<GameInterface>,
      required: true,
    },
  },
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'error',
    teams: {} as {
      next: string | null,
      previous: string | null,
      results: Array<TeamInterface>,
    },
    moreTeamsStatus: 'idle' as 'idle' | 'loading' | 'error',
    observer: null as null | IntersectionObserver,
  }),
  created() {
    this.loadTeams();
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    async loadTeams() {
      this.status = 'loading';

      const responseData = await this.api({
        url: `games/${this.game.slug}/teams/`,
      });

      if (responseData.status === 200) {
        this.teams = responseData.body;
        this.status = 'loaded';

        if (this.teams.next) {
          this.$nextTick(() => {
            this.observer = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.intersectionRatio) {
                  this.loadMoreTeams();
                }
              });
            });

            this.observer.observe(this.$refs.loadMoreTeamsButton as HTMLButtonElement);
          });
        }
      } else {
        this.status = 'error';
      }
    },
    async loadMoreTeams() {
      this.moreTeamsStatus = 'loading';

      const responseData = await this.api({
        url: this.teams.next as string,
      });

      if (responseData.status === 200) {
        this.teams.next = responseData.body.next;
        this.teams.previous = responseData.body.previous;
        this.teams.results = this.teams.results.concat(responseData.body.results);
        this.moreTeamsStatus = 'idle';
      } else {
        this.moreTeamsStatus = 'error';
      }
    },
  },
});


import linkify from 'linkifyjs/html';
import { defineComponent, PropType } from 'vue';
import { GameInterface } from '@/interfaces/games';

export default defineComponent({
  props: {
    game: {
      type: Object as PropType<GameInterface>,
      required: true,
    },
  },
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'error',
    gameInfo: {} as {
      category: string;
      platforms: string[];
      summary: string;
    },
  }),
  created() {
    this.loadGameInfo();
  },
  methods: {
    summaryHtml() {
      return linkify(
        this.linebreaksbr(this.gameInfo.summary),
        {
          defaultProtocol: 'https',
        },
      );
    },
    async loadGameInfo() {
      this.status = 'loading';

      const responseData = await this.api({
        url: `games/${this.game.slug}/info/`,
      });

      if (responseData.status === 200) {
        this.gameInfo = responseData.body;
        this.status = 'loaded';
      } else {
        this.status = 'error';
      }
    },
  },
});

<template>
  <dialog
    ref="modal"
    class="modal subtle-scrollbar"
    :class="{ 'modal-lg': large, 'modal-transition': modalShowing}"
    @click="backdropClicked"
  >
    <div>
      <div class="modal-header">
        <slot name="close">
          <button
            type="button"
            class="btn-transparent modal-close js-modal-close"
            :aria-label="closeLabel"
            @click="closeModal"
          >
            <x-icon />
          </button>
        </slot>

        <slot name="header" />
      </div>

      <div class="modal-body">
        <slot />
      </div>

      <slot name="footer" />
    </div>
  </dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { XIcon } from '@zhuowenli/vue-feather-icons';

export default defineComponent({
  components: {
    XIcon,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
    closeLabel: {
      type: String,
      required: false,
      default: 'Close',
    },
  },
  emits: [
    'modalClosing',
    'modalClosed',
  ],
  data: () => ({
    modalShowing: false,
    polyfilled: false,
  }),
  watch: {
    show() {
      this.showModalIfNecessary();
    },
  },
  mounted() {
    this.showModalIfNecessary();
  },
  methods: {
    backdropClicked(e: MouseEvent) {
      const modal = this.$refs.modal as HTMLDialogElement;

      if (e.target === modal) {
        this.closeModal();
      }
    },
    closeModal() {
      const modal = this.$refs.modal as HTMLDialogElement;
      modal.addEventListener('transitionend', this.closeModal2);
      this.modalShowing = false;
      this.$emit('modalClosing');
    },
    closeModal2(e: TransitionEvent) {
      const modal = this.$refs.modal as HTMLDialogElement;

      if (e.target === modal) {
        modal.removeEventListener('transitionend', this.closeModal2);
        // @ts-expect-error Property 'close' does not exist on type 'HTMLDialogElement'.
        modal.close();
        this.$emit('modalClosed');
      }
    },
    showModalIfNecessary() {
      if (this.show) {
        if (!this.polyfilled) {
          this.polyfilled = true;
          window.dialogPolyfill.registerDialog(this.$refs.modal);
        }

        const modal = this.$refs.modal as HTMLDialogElement;
        // @ts-expect-error Property 'showModal' does not exist on type 'HTMLDialogElement'.
        modal.showModal();
        window.app.activeModals.push(modal);
        this.modalShowing = true;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    margin: 0;
    padding: 0;
    width: 50vw;
    min-width: 280px;
    max-width: 500px;
    height: 50vh;
    max-height: 500px;
    color: #fff;
    background-color: var(--gray-darker);
    border: 1px solid var(--gray-darkest);
    border-radius: 10px;
    word-wrap: break-word;
    overflow-y: auto;
    overscroll-behavior-y: contain;
    transform: translate(-50%, 100vh);

    &[open].modal-transition {
      transform: translate(-50%, -50%);
    }

    > div {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  .modal-lg {
    width: 85vw;
    min-width: 280px;
    max-width: 1300px;
    height: 85vh;
    max-height: 700px;
  }

  .modal,
  .modal[open] + :deep(.backdrop) {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.2s, opacity 0.2s linear, transform 0.2s linear;
  }

  .modal[open]::backdrop {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.2s, opacity 0.2s linear, transform 0.2s linear;
  }

  .modal[open].modal-transition,
  .modal[open].modal-transition + :deep(.backdrop) {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }

  .modal[open].modal-transition::backdrop {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }

  .modal[open] + :deep(.backdrop) {
    background-color: rgba(0, 0, 0, 0.5);
    overscroll-behavior-y: contain;
  }

  .modal[open]::backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    overscroll-behavior-y: contain;
  }

  .modal-header {
    position: sticky;
    top: 0;
    padding: 1rem 2rem 0;
    height: 50px;
    background-color: var(--gray-darker);
    z-index: 1;

    :deep(h2) {
      padding-bottom: 0;
      color: #fff;
      font-size: 16px;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .modal-close {
    position: absolute;
    top: 50%;
    right: 14px;
    color: #fff;
    font-size: 18px;
    transform: translateY(-50%);
  }

  .modal-body {
    flex: 1;
    height: 1px;
    padding-bottom: 1rem;
  }

  :deep(.modal-footer) {
    position: sticky;
    bottom: 0;
    padding: 1rem 2rem;
    border-top: 1px solid var(--gray-darkest);
    background-color: var(--gray-darker);
    text-align: center;
    z-index: 1;
  }
</style>

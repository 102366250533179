
import { defineComponent, PropType } from 'vue';
import Modal from '@/components/Modal.vue';
import ProfileImage from '@/components/users/ProfileImage.vue';
import { CoachUserInterface, GameInterface } from '@/interfaces/games';

export default defineComponent({
  components: {
    Modal,
    ProfileImage,
  },
  props: {
    game: {
      type: Object as PropType<GameInterface>,
      required: true,
    },
  },
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'error',
    coaches: {} as {
      next: string | null,
      previous: string | null,
      results: Array<CoachUserInterface>,
    },
    moreCoachesStatus: 'idle' as 'idle' | 'loading' | 'error',
    observer: null as null | IntersectionObserver,
    gamePlatforms: [] as Array<string>,
    userIsACoach: false,
    togglingCoachStatus: false,
    platformsModalShowing: false,
    platformsErrorMessageShowing: false,
    selectedPlatforms: [] as Array<string>,
  }),
  watch: {
    platformsErrorMessageShowing() {
      if (this.platformsErrorMessageShowing) {
        this.$nextTick(() => {
          this.scrollToFirstError((this.$refs.platformsModal as InstanceType<typeof Modal>).$el);
        });
      }
    },
  },
  created() {
    this.loadCoaches();
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    async loadCoaches() {
      this.status = 'loading';

      const responseData = await this.api({
        url: `games/${this.game.slug}/coaches/`,
      });

      if (responseData.status === 200) {
        this.coaches = responseData.body.coaches;
        this.gamePlatforms = responseData.body.game_platforms;
        this.userIsACoach = responseData.body.user_is_a_coach;
        this.status = 'loaded';

        if (this.coaches.next) {
          this.$nextTick(() => {
            this.observer = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.intersectionRatio) {
                  this.loadMoreCoaches();
                }
              });
            });

            this.observer.observe(this.$refs.loadMoreCoachesButton as HTMLButtonElement);
          });
        }
      } else {
        this.status = 'error';
      }
    },
    async loadMoreCoaches() {
      this.moreCoachesStatus = 'loading';

      const responseData = await this.api({
        url: this.coaches.next as string,
      });

      if (responseData.status === 200) {
        this.coaches.next = responseData.body.next;
        this.coaches.previous = responseData.body.previous;
        this.coaches.results = this.coaches.results.concat(responseData.body.results);
        this.moreCoachesStatus = 'idle';
      } else {
        this.moreCoachesStatus = 'error';
      }
    },
    platformsSubmitButtonClicked() {
      this.platformsErrorMessageShowing = false;

      this.$nextTick(() => {
        if (this.selectedPlatforms.length) {
          (this.$refs.platformsModal as InstanceType<typeof Modal>).closeModal();
          this.toggleCoachStatus();
        } else {
          this.platformsErrorMessageShowing = true;
        }
      });
    },
    toggleButtonClicked() {
      if (
        !this.userIsACoach
        && this.gamePlatforms.length > 1
        && this.selectedPlatforms.length === 0
      ) {
        this.platformsModalShowing = true;
      } else {
        this.toggleCoachStatus();
      }
    },
    async toggleCoachStatus() {
      if (!this.togglingCoachStatus) {
        this.togglingCoachStatus = true;

        const json = {
          action: this.userIsACoach ? 'remove' : 'add',
        } as Record<string, string | Array<string>>;

        if (!this.userIsACoach && this.selectedPlatforms.length) {
          json.platforms = JSON.parse(JSON.stringify(this.selectedPlatforms));
        }

        const responseData = await this.api({
          url: `games/${this.game.slug}/coaches/`,
          method: 'POST',
          json,
        });

        this.togglingCoachStatus = false;

        if (responseData.ok) {
          if (!this.userIsACoach && this.selectedPlatforms.length) {
            this.selectedPlatforms = [];
          }

          this.userIsACoach = !this.userIsACoach;

          if (this.userIsACoach) {
            this.coaches.results.unshift(responseData.body);
          } else {
            this.coaches.results = this.coaches.results.filter(
              (p) => p.user.username !== this.userData.username,
            );
          }
        } else {
          const title = 'Failed to Change Your Coach Status';
          let text;

          if (responseData.status === 404) {
            text = 'This game no longer exists.';
          } else {
            text = 'Please check your connection and try again.';
          }

          this.$swal(title, text);
        }
      }
    },
  },
});
